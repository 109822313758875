import { CvUserService } from './../../services/cv-user.service';
import { CvUserData } from './../../models/cv-user-data';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CvConfig } from 'src/app/services/cv-config';

@Component({
  selector: 'app-cv-diet',
  templateUrl: './cv-diet.component.html',
  styleUrls: ['./cv-diet.component.css'],
})
export class CvDietComponent implements OnInit {
  userData: CvUserData;

  constructor(
    private userService: CvUserService,
    private router: Router,
    private config: CvConfig
  ) {}

  applyAll = false;
  isMobile = false;
  ngOnInit() {
    this.isMobile = window.innerWidth < 768;

    this.userService.getUserData().subscribe((data: CvUserData) => {
      this.userData = data;
    });
  }

  moveNext() {
    this.userService.setUserData(this.userData);
    this.router.navigate(['/general-commute', {}]);
  }

  applyToAll() {
    const n = this.userData.lifeStyle.members.length;
    if (this.applyAll && n > 1) {
      for (let i = 1; i < n; i++) {
        this.userData.lifeStyle.members[i].diet =
          this.userData.lifeStyle.members[0].diet;
      }
    }
  }

  isCountryWithDetails() {
    console.log(this.userData.lifeStyle.country);
    return this.config.countriesWithDetails.includes(
      this.userData.lifeStyle.country
    );
  }
}
