import { CvCustomer } from './cv-customer';
import { CvUserItems } from './cv-user-items';
export class CvTransaction {
    transactionUid: string;
    customer: CvCustomer;
    lineItems: CvUserItems[];
    totalAmount: number;
    donation: {
        choice: string;
        amount: number;
    };
}

export class CvCheckoutSession {
    checkoutUrl: string;
}
