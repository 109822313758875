<div class="cv-container">
  <div class="row text-center">
    <div class="col-md-5 col-xs-12 cv-col-l">
      <div class="cv-pg-info">
        <h1>Calculate your<br> impact</h1>
        <p>
          Start your journey to carbon neutrality today. Offset your carbon footprint and prevent carbon pollution
          before
          it happens.
        </p>
      </div>
      <div class="row cv-leftFooter">
        <a routerLink="/home">Return home</a>
      </div>
    </div>
    <div class="col-md-7 col-xs-12 cv-col-r text-right">
      <div class="row text-right">
        <div class="col img">
          <img src="/assets/img/calculators/CV_GeneralLifestyle@2x.jpg">
          <p>
            <a routerLink="/general-about-you">General Lifestyle</a>
          </p>
        </div>
      </div>
      <div class="row text-right">
        <div class="col img">
          <img src="/assets/img/calculators/CV_Cars@2x.jpg" style="margin-top: 0;">
          <p>
            <a routerLink="/cars">Cars</a>
          </p>
        </div>
      </div>
      <div class="row text-right">
        <div class="col img">
          <img src="/assets/img/calculators/CV_Flights@2x.jpg">
          <p>
            <a routerLink="/flights">Flights</a>
          </p>
        </div>
      </div>
      <div class="row text-right">
        <div class="col img">
          <img src="/assets/img/calculators/CV_Appliances@2x.jpg" style="margin-top: 0;">
          <p>
            <a routerLink="/appliances">Appliances</a>
          </p>
        </div>
      </div>
      <div class="row text-right">
        <div class="col img">
          <img src="/assets/img/calculators/CV_Stock@2x.jpg" style="margin-top: 0;">
          <p>
            <a routerLink="/stocks">Stock Portfolio</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>