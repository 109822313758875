import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CvConfig } from 'src/app/services/cv-config';
import { CvUserData } from './../../models/cv-user-data';
import { CvUserService } from './../../services/cv-user.service';

@Component({
  selector: 'app-cv-commute',
  templateUrl: './cv-commute.component.html',
  styleUrls: ['./cv-commute.component.css'],
})
export class CvCommuteComponent implements OnInit {
  userData: CvUserData;
  applyAll = false;

  constructor(
    private userService: CvUserService,
    private router: Router,
    private config: CvConfig
  ) {}

  isMobile = false;
  ngOnInit() {
    this.isMobile = window.innerWidth < 768;

    this.userService.getUserData().subscribe((data: CvUserData) => {
      this.userData = data;
    });
  }

  moveNext() {
    this.userService.setUserData(this.userData);
    this.router.navigate(['/general-flight', {}]);
  }

  applyToAll() {
    const n = this.userData.lifeStyle.members.length;
    if (this.applyAll && n > 1) {
      for (let i = 1; i < n; i++) {
        this.userData.lifeStyle.members[i].commute =
          this.userData.lifeStyle.members[0].commute;
      }
    }
  }

  isCountryWithDetails() {
    return this.config.countriesWithDetails.includes(
      this.userData.lifeStyle.country as any
    );
  }
}
