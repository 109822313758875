<div class="cv-container">
  <div class="row text-left">
    <div class="col-md-5 col-xs-12 cv-col-l cv-summary bg-primary">
      <div style="margin: 4rem">
        <p>
          Make an Additional Donation<br>
          <span style="font-size: small;">Support our purchase of carbon pollution permits so polluters can't use them.</span>
        </p>
        <input type="text" class="inline-btn donate-btn"
          [ngClass]="{'cv-btn-focus': this.transaction.donation.choice == 'D0'}"
          (click)="this.transaction.donation = {choice:'D0',amount:0}; this.prepareItems();" value="$0"
          readonly="readonly" />
        <span class="cv-gap"></span>
        <input type="text" class="inline-btn donate-btn"
          [ngClass]="{'cv-btn-focus': this.transaction.donation.choice == 'D50'}"
          (click)="this.transaction.donation = {choice:'D50',amount:50}; this.prepareItems();" value="$50"
          readonly="readonly" />
        <input type="text" class="inline-btn donate-btn"
          [ngClass]="{'cv-btn-focus': this.transaction.donation.choice == 'D100'}"
          (click)="this.transaction.donation = {choice:'D100',amount:100}; this.prepareItems();" value="$100"
          readonly="readonly" />
        <span class="cv-gap"></span>
        <input type="number" class="inline-btn donate-btn" #customDonation min="0"
          [ngClass]="{'cv-btn-focus': this.transaction.donation.choice == 'DC'}"
          (click)="customDonation.value = (customDonation.value<0)?-1*customDonation.value:customDonation.value;this.transaction.donation = {choice:'DC',amount:customDonation.value*1}; this.prepareItems();"
          (change)="customDonation.value = (customDonation.value<0)?-1*customDonation.value:customDonation.value;this.transaction.donation = {choice:'DC',amount:customDonation.value*1}; this.prepareItems();"
          placeholder="Custom" value="{{(this.transaction.donation.choice == 'DC')? this.transaction.donation.amount:''}}" />
        <hr class="bg-colorA">
        
        <p>Offset Now</p>
        <hr class="bg-colorA">
        <div class="lineItem" *ngFor="let item of this.transaction.lineItems; let idx=index">
          <a class="remove-icon" (click)="removeItem(idx)" *ngIf="(item.removable)">&#10007;</a> {{item.description}}
          <div class="row">
            <div class="col col-8">{{item.emission}} tons of Co2</div>
            <div class="col col-4 text-right"><span> ${{item.amount.toFixed(2)}}</span></div>
          </div>
        </div>
        <div class="lineItem" *ngIf="this.transaction.lineItems.length ==0">
          <a class="remove-icon"></a> No item available in your basket
          <div class="row">
            <div class="col col-8"></div>
            <div class="col col-4"><span></span></div>
          </div>
        </div>
        <hr class="bg-colorA">
        <p class="row">
          <span class="col">Calculated total donation<br></span>
          <span class="col" style="font-size: 2.5em; font-weight: bolder; text-align: right;">${{this.transaction.totalAmount.toFixed(2)}} US</span>
        </p>
        <p class="row"><span class="col">Climate Vault will exercise full dominion and control over all donations.</span></p>
      </div>
      
    </div>

    <div class="col-md-7 col-xs-12 cv-col-r">
      <div class="cv-col-pad">
        <h5>About you</h5>
        <br>
        <div class="alert alert-danger" role="alert" [hidden]="(this.processError == '')">
          {{this.processError}}
        </div>
        <div class="form-group">
          <label class="control-label" for="email">Your email</label>
          <input type="email" class="form-control" id="email" [(ngModel)]="this.transaction.customer.email"
            placeholder="Email address" required #email="ngModel" email="true">
          <div class="invalid-error">
            Valid email address is required.
          </div>
        </div>

        <div class="form-check">
          
          <label class="form-check-label" for="flexCheckChecked">
            <input class="form-check-input" type="checkbox" value="1" [(ngModel)]="this.transaction.customer.receiveEmail" id="flexCheckChecked">
            I agree to receive periodic emails from Climate Vault. 
          </label>
          <span style="display: block;font-size: 0.6em;color: #999;">You can unsubscribe at any time. Please refer to our Privacy Policy for more questions or details.</span>
        </div>

        <button type="button" class="button purchase-btn" (click)="processTrasaction()"
          [disabled]="((this.transaction.totalAmount == 0)|| email.invalid || this.processing)">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
            [hidden]="!this.processing"></span>
            Enter payment information</button>
      </div>
    </div>

  </div>
</div>