<div class="cv-container home">
  <div class="cv-pg-info">
    <h1>Take action against climate change</h1>
    <p>
      Take action against climate change by shrinking your carbon footprint and starting your journey toward carbon neutrailty. 
    </p>
  </div>
  <div class="row text-center">
    <div class="col-md-6 col-xs-12 img">
      <img src="/assets/img/UnderstandYourImpact_bg.jpg">
      <p>
        Understand your impact. <br />
        <a routerLink="/selection">Calculate your carbon footprint</a>
      </p>
    </div>
    <div class="col-md-6 col-xs-12 img">
      <img src="/assets/img/BuyCarbonOffset_bg.jpg">
      <p>
        Buy carbon offests <br />
        <a routerLink="/offset-now">Offset now</a>
      </p>
    </div>
  </div>
  <div class="row text-center">
    <div class="col-12" style="margin-top: 30px;">
      <a href="">Get your Carbon Warrior Badge</a>
    </div>
  </div>
</div>