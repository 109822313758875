import { CvApiResponse } from './../../models/cv-api-response';
import { CvTransaction, CvCheckoutSession } from './../../models/cv-transaction';
import { CvCustomer } from './../../models/cv-customer';
import { CvUserDonation } from './../../models/cv-user-items';
import { CvCountry } from './../../models/cv-country';
import { CvUserService } from './../../services/cv-user.service';
import { CvUserData } from './../../models/cv-user-data';
import { Component, OnInit } from '@angular/core';
import { CvAddress } from 'src/app/models/cv-address';
import { CvPromotioncode } from 'src/app/models/cv-invitationcode';

@Component({
  selector: 'app-cv-payment',
  templateUrl: './cv-payment.component.html',
  styleUrls: ['./cv-payment.component.css']
})
export class CvPaymentComponent implements OnInit {

  processError = '';
  donateAmount = 0;
  processing = false;
  userData: CvUserData;
  countryList: CvCountry[] = [];
  usStateList: string[];
  transaction: CvTransaction;
  totalAmount = 0;
  /* using this variable, as for calculation we use country code, but for transaction we need country name*/
  countryName: string;

  constructor(private userService: CvUserService) { }

  ngOnInit() {

    this.transaction = new CvTransaction();
    this.transaction.customer = new CvCustomer();
    this.transaction.customer.address = new CvAddress();
    this.transaction.donation = this.userService.loadUserDonation();

    this.userService.loadUsStates()
      .subscribe((data: string[]) => {
        this.usStateList = data;
      });

    this.userService.getUserData()
      .subscribe((data: CvUserData) => {
        this.userData = data;
        this.countryName = this.userData.lifeStyle.countryName;
        this.userService.loadCountries()
          .subscribe((countrie: CvCountry[]) => {
            this.countryList = countrie;
          });
      });

    this.transaction.lineItems = this.userService.loadUserItems();
    this.prepareItems();
  }

  processTrasaction() {
    let promocode = '';
    const n = this.transaction.lineItems.length;
    for (let i = 0; i < n; i++) {
      // Looking for the first promocode
      if (this.transaction.lineItems[i].promocode !== undefined) {
        promocode = this.transaction.lineItems[i].promocode;
        break;
      }
    }

    if (promocode === '') {
      this.processPayment();
    } else {
      const cvPromotioncode = new CvPromotioncode();
      cvPromotioncode.promocode = promocode;
      cvPromotioncode.isUsed = true;
      this.userService.userPromotioncode(cvPromotioncode).subscribe(
        (data: CvPromotioncode) => {
          if (data.isValid && data.isUsed) {
            this.processPayment();
          } else {
            const msg = ((data as unknown) as CvApiResponse).message;
            if (msg !== undefined) {
              alert(msg);
            }
          }
        }
      );
    }
  }

  processPayment_old() {
    this.processing = true;
    this.transaction.customer.address.country = this.countryName;
    this.userService.addTransaction(this.transaction).subscribe((data: CvTransaction) => {
      if (data === undefined) {
        this.processing = false;
        this.processError = 'Error processing your transaction';
      } else {
        const gurl = this.userService.prepareGivingUrl(data);
        window.location.href = gurl;
      }
    });
  }

  processPayment() {
    this.processing = true;
    this.transaction.customer.address.country = this.countryName;
    this.userService.initiateTransaction(this.transaction).subscribe((data: CvCheckoutSession) => {
      if (data === undefined) {
        this.processing = false;
        this.processError = 'Error processing your transaction';
      } else {
        window.location.href = data.checkoutUrl;
      }
    });
  }

  prepareItems() {
    const n = this.transaction.lineItems.length;
    this.transaction.totalAmount = 0;
    for (let i = 0; i < n; i++) {
      this.transaction.lineItems[i].emission = Math.ceil(this.transaction.lineItems[i].emission * 100) / 100;
      this.transaction.lineItems[i].amount = Math.ceil(this.transaction.lineItems[i].amount * 100) / 100;
      this.transaction.totalAmount += this.transaction.lineItems[i].amount;
    }

    const d: CvUserDonation = this.userService.loadUserDonation();
    d.amount = this.transaction.donation.amount;
    this.userService.storeUserDonation(d);
    this.transaction.totalAmount += Math.abs(this.transaction.donation.amount);

    this.transaction.totalAmount = Math.ceil(this.transaction.totalAmount * 100) / 100;
  }

  removeItem(idx) {
    this.transaction.lineItems.splice(idx, 1);
    this.userService.userItems = this.transaction.lineItems;
    this.userService.storeUserItems();
    this.prepareItems();
  }
}


