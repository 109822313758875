import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cv-selection',
  templateUrl: './cv-selection.component.html',
  styleUrls: ['./cv-selection.component.css']
})
export class CvSelectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
