<div class="cv-container">
  <div class="row text-center">
    <div class="col-md-5 col-xs-12 cv-col-l">
      <div class="cv-pg-info">
        <h1>Become carbon neutral today</h1>
        <p>
          Start your journey to carbon neutrality today. Offset your carbon footprint and prevent carbon pollution
          before
          it happens.
        </p>
      </div>
      <div class="row cv-leftFooter">
        <a routerLink="/home">Return home</a>
      </div>
    </div>
    <div class="col-md-7 col-xs-12 cv-col-r text-right">
      <div class="row text-right">
        <div class="col img">
          <img src="/assets/img/OffsetNowMe_bg.jpg">
          <p>
            <a routerLink="/offset-now-me">This is for me</a>
          </p>
        </div>
      </div>
      <div class="row text-right">
        <div class="col img">
          <img src="/assets/img/OffsetNowGift_bg.jpg" style="margin-top: 0;">
          <p>
            <a routerLink="/offset-now-gift">This is a gift</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>