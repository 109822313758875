<div class="cv-container">
  <app-cv-nav-bar [pageTitle]="'General Lifestyle Calculator'"></app-cv-nav-bar>
  <div class="row text-center">
    <div class="col-12">
      <div class="cv-pg-info">
        <div class="cv-pg-title">
          <a
            routerLink="/general-lifestyle"
            class="button button-hollow button-prev back-btn"
            title="Back"
            >Back</a
          >
          <h2><span style="color: #ccc">1/4</span> About you</h2>
          <p>Tell us a little about yourself</p>
        </div>
        <div class="cv-pg-content">
          <div class="form-group">
            <div class="form-group">
              <label class="control-label"
                >How many people are in your household?</label
              >
              <input
                type="number"
                [(ngModel)]="this.household"
                min="1"
                class="form-control"
                (change)="updateMembers()"
              />
            </div>
            <div class="members p-1 row">
              <label class="control-label" style="display: block; width: 100%"
                >Name the people in your house (optional)</label
              >
              <div
                class="col-md-6 col-xs-12"
                *ngFor="let m of this.userData.lifeStyle.members; index as i"
              >
                <label class="control-label">{{ 'Person ' + (i + 1) }}</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="this.userData.lifeStyle.members[i].name"
                  placeholder="{{ 'Person ' + (i + 1) }}"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label"
              >How many pets in your household?</label
            >
            <input
              type="number"
              [(ngModel)]="this.userData.lifeStyle.pets"
              min="0"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label class="control-label">What country do you live in?</label>
            <select
              [(ngModel)]="this.userData.lifeStyle.country"
              class="form-control"
              placeholder="select"
            >
              <option
                *ngFor="let country of countryList"
                value="{{ country.code }}"
                [selected]="userData.lifeStyle.country == country.code"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label class="control-label">Do you have a client code?</label>
            <input
              type="text"
              [(ngModel)]="this.userData.clientCode"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <a
      routerLink="/general-lifestyle"
      class="button button-hollow button-prev back-btn"
      title="Back"
      >Back</a
    >
    <button (click)="moveNext()" class="button button-next next-btn">
      Next
    </button>
  </div>
</div>

