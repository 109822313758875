import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CvConfig {
  initialUserDataUrl = environment.dataPath + 'user-data.json';
  initialUserProfileUrl = environment.dataPath + 'user-profile.json';
  carsListUrl = environment.dataPath + 'cars.json';
  countriesListUrl = environment.dataPath + 'countries.json';
  usStatesListUrl = environment.dataPath + 'us-states.json';
  userCountryUrl = environment.ip2CountryUrl;
  generalLifeStyleCalculationUrl = environment.apiUrl + '?a=generalLifeStyle';
  generalLifeStyleBenchmarkUrl = environment.apiUrl + '?a=benchmark';
  metrictonUrl = environment.apiUrl + '?a=metricton';
  addTransactionUrl = environment.apiUrl + '?a=addTransaction';
  apeInvitationCodeUrl = environment.apiUrl + '?a=apeInvitationCode';
  apeMetrictonUrl = environment.apiUrl + '?a=apeMetricton';
  apeUseInvitationCodeUrl = environment.apiUrl + '?a=apeUseInvitationCode';
  givingUrl = environment.givingUrl;
  chargePerTon = 10.0;
  defaultUserData = {
    transactionId: 0,
    offsetNow: {
      countryName: '',
      country: 'US',
      period: 12,
      metricTons: 1,
    },
    lifeStyle: {
      countryName: '',
      country: 'US',
      pets: 0,
      members: [
        {
          id: 1,
          name: 'Person 1',
          pets: 0,
          diet: 'AVERAGE',
          commute: 'AVERAGE',
          flight: 'AVERAGE',
          emission: 0,
        },
      ],
    },
  };
  countriesWithDetails = [
    'US',
    'CA',
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IS',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'RU',
    'RS',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'GB',
  ];
}
