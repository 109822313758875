import { CvUserService } from './../../services/cv-user.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cv-nav-bar',
  templateUrl: './cv-nav-bar.component.html',
  styleUrls: ['./cv-nav-bar.component.css']
})
export class CvNavBarComponent implements OnInit {

  @Input() pageTitle: string;
  userItemCount: number;

  constructor(private userService: CvUserService) { }

  ngOnInit() {
    this.userService.loadUserItems();
    this.userItemCount = this.userService.getUserItemCount();
  }
}
