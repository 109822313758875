<div class="cv-container" *ngIf="paymentStatus == 'SUCCESS'">
  <div class="row text-center">
    <h3 class="title">Congrats! Your help made a difference to our plannet</h3>
    <div style="width: 100%;">
      <img src="/assets/img/success-payment.png" />
    </div>
    <h3 class="sub-title">
      Help us accelerate the Journey to Carbon Zero
    </h3>
    <p style="width: 100%;">
      Climate Vault supports development of competitive carbon removal technologies of the future.
    </p>
    <p class="ref">
      Payment Ref: {{paymentUid}}
    </p>
  </div>
</div>

<div class="cv-container" *ngIf="paymentStatus !== 'SUCCESS'">
  <div class="row text-center">
    <h3 class="title">Somthing went less than ideal</h3>
    <div style="width: 100%;">
      <img src="/assets/img/failed-payment.png" />
    </div>
    <h3 class="sub-title">
      Payment error
    </h3>
    <p style="width: 100%;">
      Your credit card payment wasn't successful. Please click button below to try again.
    </p>
    <p class="ref">
      Payment Ref: {{paymentUid}}
    </p>
  </div>
</div>