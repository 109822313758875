import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cv-payment-result',
  templateUrl: './cv-payment-result.component.html',
  styleUrls: ['./cv-payment-result.component.css']
})
export class CvPaymentResultComponent implements OnInit {


  paymentUid: string;
  paymentStatus: string;
  message: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.paymentUid = atob(this.route.snapshot.paramMap.get('paymentUid'));
    this.paymentStatus = this.route.snapshot.paramMap.get('status');
    this.message = atob(this.route.snapshot.paramMap.get('message'));
  }

}
