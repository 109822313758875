import { CvUserData } from './cv-user-data';
export class CvUserItems {
    calculationUid: string;
    promocode: string;
    description: string;
    category: string;
    emission: number;
    amount: number;
    details: any;
    removable: boolean;
}

export class CvUserDonation {
    amount: number;
    choice: string;
}
