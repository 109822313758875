<nav class="navbar navbar-expand-sm">
  <div class="img">
    <img src="./assets/img/cv-logo.png" class="navbar-brand">
  </div>
  <div class="title">
    <span class="navbar-nav align-items-center">{{pageTitle}}</span>
  </div>
  <div class="icon mr-md-2">
      <a routerLink="/payment"><i class="fa fa-shopping-cart"><span class="badge badge-info" [hidden]="(this.userItemCount < 1)">{{this.userItemCount}}</span></i></a>
  </div>
</nav>
