import { CvPaymentResultComponent } from './components/cv-payment-result/cv-payment-result.component';
import { CvPackageComponent } from './components/cv-package/cv-package.component';
import { CvInvitationcodeComponent } from './components/cv-invitationcode/cv-invitationcode.component';
import { CvGeneralLifestyleComponent } from './components/cv-general-lifestyle/cv-general-lifestyle.component';
import { environment } from './../environments/environment';
import { CvCarsComponent } from './components/cv-cars/cv-cars.component';
import { CvResultsComponent } from './components/cv-results/cv-results.component';
import { CvGeneralFlightComponent } from './components/cv-general-flight/cv-general-flight.component';
import { CvCommuteComponent } from './components/cv-commute/cv-commute.component';
import { CvDietComponent } from './components/cv-diet/cv-diet.component';
import { CvConfirmationComponent } from './components/cv-confirmation/cv-confirmation.component';
import { CvSelectionComponent } from './components/cv-selection/cv-selection.component';
import { CvOffsetGiftComponent } from './components/cv-offset-gift/cv-offset-gift.component';
import { CvPaymentComponent } from './components/cv-payment/cv-payment.component';
import { CvOffsetNowMeComponent } from './components/cv-offset-now-me/cv-offset-now-me.component';
import { CvOffsetNowComponent } from './components/cv-offset-now/cv-offset-now.component';
import { CvHomeComponent } from './components/cv-home/cv-home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CvAboutYouComponent } from './components/cv-about-you/cv-about-you.component';


const routes: Routes = [
  { path: '', redirectTo: environment.defaultPage, pathMatch: 'full' },
  { path: 'home', component: CvHomeComponent },
  { path: 'offset-now', component: CvOffsetNowComponent },
  { path: 'offset-now-me', component: CvOffsetNowMeComponent },
  { path: 'offset-now-gift', component: CvOffsetGiftComponent },
  { path: 'general-about-you', component: CvAboutYouComponent },
  { path: 'general-diet', component: CvDietComponent },
  { path: 'general-commute', component: CvCommuteComponent },
  { path: 'general-flight', component: CvGeneralFlightComponent },
  { path: 'cars', component: CvCarsComponent },
  { path: 'selection', component: CvSelectionComponent },
  { path: 'results', component: CvResultsComponent },
  { path: 'payment', component: CvPaymentComponent },
  { path: 'paymentresult/:paymentUid/:status/:message', component: CvPaymentResultComponent },
  { path: 'confirmation', component: CvConfirmationComponent },
  // { path: 'general-lifestyle', component: CvGeneralLifestyleComponent },
  { path: 'invitationcode', component: CvInvitationcodeComponent },
  { path: 'package', component: CvPackageComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
