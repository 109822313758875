import { Router } from '@angular/router';
import { CvUserItems } from './../../models/cv-user-items';
import { CvConfig } from './../../services/cv-config';
import { CvGeneralLifestyleData } from 'src/app/models/cv-general-lifestyle-data';
import { CvUserService } from './../../services/cv-user.service';
import { CvUserData } from './../../models/cv-user-data';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cv-results',
  templateUrl: './cv-results.component.html',
  styleUrls: ['./cv-results.component.css'],
})
export class CvResultsComponent implements OnInit {
  userData: CvUserData;
  userItemCount: number;
  resultToPxFactor = 5;
  result = 0;
  resultPx = 0;
  coResult = 0;
  coResultPx = 0;
  worldResult = 0;
  worldResultPx = 0;
  orgResult = -1;
  orgResultPx = 0;
  item: any = null;
  membersCount = 1;

  constructor(
    private userService: CvUserService,
    private config: CvConfig,
    private router: Router
  ) {}

  ngOnInit() {
    this.userService.loadUserItems();

    this.userService.getUserData().subscribe((data: CvUserData) => {
      this.userData = data;
      this.userData.lifeStyle.externalReferenceId = this.userData.clientCode;
      this.userService
        .calculateEmissionForGeneralLifeStyle(this.userData.lifeStyle)
        .subscribe((resultData: CvGeneralLifestyleData) => {
          if (resultData === undefined) {
            this.result = null;
            return false;
          }
          let r = 0;
          let charge = 0;
          const n = resultData.members.length;
          this.membersCount = resultData.members.length;
          for (let i = 0; i < n; i++) {
            this.userData.lifeStyle.members[i].emission =
              resultData.members[i].emission;
            this.userData.lifeStyle.members[i].charge =
              resultData.members[i].charge;
            r += this.userData.lifeStyle.members[i].emission;
            charge +=
              this.userData.lifeStyle.members[i].charge > 0
                ? this.userData.lifeStyle.members[i].charge
                : 0;
          }
          this.result = Math.ceil(r * 100) / 100;
          this.resultPx = Math.ceil(this.result * this.resultToPxFactor);

          this.item = new CvUserItems();
          this.item.calculationUid = resultData.calculationUid;
          this.item.description =
            'General Lifestyle Offset for ' +
            n +
            (n > 1 ? ' People' : ' Person');
          this.item.category = 'LIFESTYLE';
          this.item.amount = charge;
          this.item.emission = r;
          this.item.details = this.userData.lifeStyle;

          if (this.userData.clientCode)
          {
            this.userService.getGeneralLifeStyleBenchmark(this.userData.clientCode).subscribe(resultData=> {
              
                if (resultData === undefined) {
                  this.orgResult = null;
                  return false;
                }
                
                this.orgResult = resultData
                this.orgResultPx = Math.ceil(
                  this.orgResult * this.resultToPxFactor
                );
              });
            }
      });

      this.userService
        .calculateEmissionForGeneralLifeStyleCountryAverage()
        .subscribe((resultData: CvGeneralLifestyleData) => {
          if (resultData === undefined) {
            this.coResult = null;
            return false;
          }
          let r = 0;
          const n = this.userData.lifeStyle.members.length;
          r = resultData.members[0].emission * n;
          this.coResult = Math.ceil(r * 100) / 100;
          this.coResultPx = Math.ceil(this.coResult * this.resultToPxFactor);
        });

      this.userService
        .calculateEmissionForGeneralLifeStyleWorldAverage()
        .subscribe((resultData: CvGeneralLifestyleData) => {
          if (resultData === undefined) {
            this.worldResult = null;
            return false;
          }
          let r = 0;
          const n = this.userData.lifeStyle.members.length;
          r = resultData.members[0].emission * n;
          this.worldResult = Math.ceil(r * 100) / 100;
          this.worldResultPx = Math.ceil(
            this.worldResult * this.resultToPxFactor
          );
        });

      this.getBasketCount();
    });
  }

  getBasketCount() {
    this.userItemCount = this.userService.getUserItemCount();
  }

  addToBasket() {
    if (this.item !== null) {
      this.userService.addUserItem(this.item);
      this.getBasketCount();
      this.userService.storeUserItems();
    }
  }

  buyNow() {
    if (this.item !== null) {
      this.addToBasket();
      this.router.navigate(['/payment', {}]);
    }
  }
}
