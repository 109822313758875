import { CvMetricton } from './../../models/cv-invitationcode';
import { CvUserItems, CvUserDonation } from './../../models/cv-user-items';
import { CvGeneralLifestyleData } from 'src/app/models/cv-general-lifestyle-data';
import { CvUserService } from './../../services/cv-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { _isNumberValue } from '@angular/cdk/coercion';

/**  http://localhost:4200/#/package?package=lifestyle&data=eyJjb3VudHJ5TmFtZSI6IlVuaXRlZCBTdGF0ZXMiLCJjb3VudHJ5IjoiVVMiLCJwZXRzIjowLCJtZW1iZXJzIjpbeyJpZCI6MSwibmFtZSI6IlBlcnNvbiAxIiwicGV0cyI6MCwiZGlldCI6IkFWRVJBR0UiLCJjb21tdXRlIjoiQVZFUkFHRSIsImZsaWdodCI6IkFWRVJBR0UifV19

{"countryName":"United States","country":"US","pets":1,"members":[{"id":1,"name":"Person 1","pets":1,"diet":"VEGETARIAN","commute":"AVERAGE","flight":"AVERAGE","emission":0},{"id":2,"name":"Person 2","commute":"AVERAGE","flight":"AVERAGE","diet":"NO_MEAT"}]}


// http://localhost:4200/#/package?package=metricton&data=eyJtZXRyaWN0b25zIjogMTJ9
{"metrictons": 12}

*/

@Component({
  selector: 'app-cv-package',
  templateUrl: './cv-package.component.html',
  styleUrls: ['./cv-package.component.scss']
})
export class CvPackageComponent implements OnInit {

  private item: CvUserItems;
  private donation: CvUserDonation;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: CvUserService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const prepack = params['package'];
      const donationAmount = params['donateAmt'] * 1;
      const data = params['data'];
      let isValidData = false;

      this.checkDonationAmount(donationAmount);

      if (prepack == 'lifestyle') {
        const lifeStyleData: CvGeneralLifestyleData = this.decodeData(data);
        if (lifeStyleData) {
          isValidData = true;
          this.calculateLifestyleEmission(lifeStyleData);
        }
      } else
        if (prepack == 'metricton') {
          const metrictonData: CvMetricton = this.decodeData(data);
          if (metrictonData) {
            isValidData = true;
            this.calculateMetrictonEmission(metrictonData);
          }
        }

      if (!isValidData) {
        this.buyNow();
      }
    });
  }

  checkDonationAmount(donationAmount: number) {
    if (donationAmount > 0) {
      this.donation = new CvUserDonation();
      this.donation.amount = donationAmount;
      this.userService.storeUserDonation(this.donation);
    }
  }

  decodeData(data) {
    try {
      return JSON.parse(atob(data));
    } catch (err) {
      // console.error(err);
      return undefined;
    }

  }

  calculateLifestyleEmission(lifeStyleData: CvGeneralLifestyleData) {
    this.userService.calculateEmissionForGeneralLifeStyle(lifeStyleData)
      .subscribe((resultData: CvGeneralLifestyleData) => {
        if (resultData === undefined) {
          return false;
        }
        let r = 0;
        let charge = 0;
        const n = resultData.members.length;
        for (let i = 0; i < n; i++) {
          lifeStyleData.members[i].emission = resultData.members[i].emission;
          lifeStyleData.members[i].charge = resultData.members[i].charge;
          r += lifeStyleData.members[i].emission;
          charge += (lifeStyleData.members[i].charge > 0) ? lifeStyleData.members[i].charge : 0;
        }

        this.item = new CvUserItems();
        this.item.calculationUid = resultData.calculationUid;
        this.item.description = 'General Lifestyle Offset for ' + n + ((n > 1) ? ' People' : ' Person');
        this.item.category = 'LIFESTYLE';
        this.item.amount = charge;
        this.item.emission = r;
        this.item.details = lifeStyleData;
        this.buyNow();
      });
  }


  calculateMetrictonEmission(metrictonData: CvMetricton) {
    this.userService.calculateChargeByMetricton(metrictonData)
      .subscribe((mtResult: CvMetricton) => {
        if (mtResult.charge * 1 > 0) {
          this.item = new CvUserItems();
          this.item.calculationUid = mtResult.calculationUid;
          this.item.promocode = '';
          this.item.description = 'Metric ton Offset';
          this.item.category = 'METRICTON';
          this.item.amount = mtResult.charge;
          this.item.emission = metrictonData.metrictons;
          this.item.details = 'Metric tons';
          this.item.removable = true;
          this.buyNow();
        }
      });
  }

  addToBasket() {
    if (this.item) {
      this.userService.addUserItem(this.item);
      this.userService.storeUserItems();
    }
  }

  buyNow() {
    this.addToBasket();
    this.router.navigate(['/payment', {}]);
  }
}
