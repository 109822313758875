<div class="cv-container">
    <app-cv-nav-bar [pageTitle]="'Purchase Carbon Emission'"></app-cv-nav-bar>
    <div class="row text-center">
        <div class="col-12">
            <div class="cv-pg-info">
                <div class="cv-pg-title">
                    <h1>Aperio</h1>
                    <p></p>
                </div>
                <div class="cv-pg-content">
                    <div class="alert alert-danger" role="alert" [hidden]="!(errorMessage !== '')">{{errorMessage}}</div>
                    <div class="form-group">
                        <div class="form-group">
                            <label class="control-label">Enter your invitation code</label>
                            <input type="text" [(ngModel)]="this.invitationcode" class="form-control"
                                placeholder="e.g. 123456-ABV463" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label">Enter metric tons</label>
                        <input type="number" [(ngModel)]="this.metrictons" min="1" class="form-control" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <button (click)="buyNow()" class="btn btn-success next-btn">Buy Now</button>
    </div>
</div>