import { CvCarData } from './../../models/cv-car-data';
import { CvUserService } from './../../services/cv-user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cv-cars',
  templateUrl: './cv-cars.component.html',
  styleUrls: ['./cv-cars.component.css']
})
export class CvCarsComponent implements OnInit {

  cars: CvCarData[];
  carMakes: string[];
  carModels: string[];
  carYears: string[];
  carTransmissions: string[];
  carEngines: string[];

  activeTab = 'model';
  car: CvCarData = {
    make: '',
    model: '',
    year: null,
    transmission: '',
    engine: '',
    distance: 1000,
    years: 1,
    distanceUnit: 'ml'
  };

  constructor(private userService: CvUserService) { }

  ngOnInit() {
    this.userService.loadCars().subscribe((carMakes: string[]) => {
      this.carMakes = carMakes;
    });
  }

  moveNext() {

  }
}
