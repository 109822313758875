// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultPage: '/general-about-you',
  apiUrl: 'https://app-dev.climatevault.org/api/service/',
  dataPath: 'assets/data/',
  ip2CountryUrl: 'https://app-dev.climatevault.org/api/IP/',
  givingUrl: 'http://giving.uchicago.edu/site/Donation2?df_id=2660&2660.donation=form1&mfc_pref=T'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
