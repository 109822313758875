export class CvMetricton {
    calculationUid: string;
    metrictons: number;
    charge: number;
}

export class CvPromotioncode {
    promocode: string;
    prefix: string;
    isValid: boolean;
    isUsed: boolean;
}

