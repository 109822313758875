<div class="cv-container">
  <div class="row text-center">
    <div class="col-md-5 col-xs-12 cv-col-l">
      <div class="cv-pg-info">
        <h1>How much do you want to offset</h1>
        <p>
          A person produces, on average, 20.0 metric tons of CO2 each year.
        </p>
      </div>

      <div class="row cv-leftFooter">
        <a routerLink="/home">Return home</a>
      </div>

    </div>
    <div class="col-md-7 col-xs-12 cv-col-r">
      <div class="cv-col-pad">
        <h2>Offset by...</h2>
        <ul class="cv-tabs">
          <li [ngClass]="{'cv-tab-selected': (activeTab == 'tons')}">
            <a [routerLink]="" (click)="activeTab='tons'">Metric tons</a>
          </li>
          <li [ngClass]="{'cv-tab-selected': (activeTab == 'location')}">
            <a (click)="activeTab='location'">Location</a>
          </li>
        </ul>
        <div class="cv-offset-tons" [hidden]="(activeTab == 'location')">
          <p>Enter your tons</p>
          <table>
            <tr>
              <td>
                <span class="cv-minus">
                  <a class="cv-sm-icon" (click)="addMetricTon(-1)"><img src="/assets/img/minus-icon.png" alt="-" /></a>
                </span>
                <input [(ngModel)]="this.userData.offsetNow.metricTons" class="cv-amount" name="metricTons" />
                <span class="cv-plus">
                  <a class="cv-sm-icon" (click)="addMetricTon(1)"><img src="/assets/img/plus-icon.png" alt="+" /></a>
                </span>
              </td>
            </tr>
          </table>
          <p>
            metric tons of Co2
          </p>
          <p class="cv-amount">
            USD {{totalAmount | currency}}
          </p>
          <p>
            1 ton = USD {{this.pricePerTon | currency}}
          </p>

          <a href class="cv-btn">OFFSET NOW</a>
        </div>
        <div class="cv-offset-loc" [hidden]="(activeTab == 'tons')">
          <p>Enter your location</p>
          <div class="form-group">
            <label class="control-label">Country</label>
            <select [(ngModel)]="this.userData.offsetNow.country" class="form-control" placeholder="select">
              <option *ngFor="let country of countryList" value="{{country.countryCode}}"
                [selected]="userData.offsetNow.country==country.countryCode">{{country.countryName}}</option>
            </select>
          </div>
          <label class="control-label">Select number of months or years to offset</label>
          <div class="form-group">
            <label class="radio-inline cv-btn cv-btn-default"
              [ngClass]="{'cv-btn-focus': this.userData.offsetNow.period == 3}">
              <input type="radio" [(ngModel)]="this.userData.offsetNow.period" value="3"> 3 Months
            </label>
            <span class="cv-gap"></span>
            <label class="radio-inline cv-btn cv-btn-default"
              [ngClass]="{'cv-btn-focus': this.userData.offsetNow.period == 6}">
              <input type="radio" [(ngModel)]="this.userData.offsetNow.period" value="6"> 6 Months
            </label>
            <span class="cv-gap hidden-xs"></span>
            <label class="radio-inline cv-btn cv-btn-default"
              [ngClass]="{'cv-btn-focus': this.userData.offsetNow.period == 12}">
              <input type="radio" [(ngModel)]="this.userData.offsetNow.period" value="12"> 1 Year
            </label>
            <span class="cv-gap"></span>
            <label class="radio-inline cv-btn cv-btn-default"
              [ngClass]="{'cv-btn-focus': this.userData.offsetNow.period == -1}">
              <input type="radio" [(ngModel)]="this.userData.offsetNow.period" value="-1"> Custom
            </label>
          </div>
          <a href class="cv-btn">OFFSET NOW</a>
        </div>
      </div>
    </div>
  </div>
</div>