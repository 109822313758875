import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cv-offset-gift',
  templateUrl: './cv-offset-gift.component.html',
  styleUrls: ['./cv-offset-gift.component.css']
})
export class CvOffsetGiftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
