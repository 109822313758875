<div class="cv-container">
  <div class="row text-left">
    <div class="col-md-5 col-xs-12 cv-col-l">
      <div class="cv-text">
        <h2>Your Life&nbsp;Overall</h2><p>Fill in the number of people in your household and habits to calculate your overall footprint.</p>
        <a routerLink="/general-about-you" class="cv-button">Calculate your impact</a>
      </div>
    </div>
    <div class="col-md-7 col-xs-12 cv-col-r">
      <img src="/assets/img/GeneralLifestyle.jpg" />
    </div>
  </div>
</div>