import { CvPromotioncode, CvMetricton } from './../models/cv-invitationcode';
import { environment } from './../../environments/environment.prod';
import { CvTransaction, CvCheckoutSession } from './../models/cv-transaction';
import { CvUserItems, CvUserDonation } from './../models/cv-user-items';
import { CvCountry } from './../models/cv-country';
import { CvGeneralLifestyleData } from './../models/cv-general-lifestyle-data';
import { CvUserProfile } from './../models/cv-user-profile';
import { CvConfig } from './cv-config';
import { CvUserData } from './../models/cv-user-data';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CvUserService {
  userProfile: CvUserProfile;
  userData: CvUserData;
  userItems: CvUserItems[] = [];
  cars: string[];
  countries: CvCountry[];
  usStates: string[];

  constructor(private http: HttpClient, private config: CvConfig) {
    this.loadCountries();
  }

  getUserProfile(): Observable<CvUserProfile> {
    if (this.userProfile !== undefined) {
      return of(this.userProfile);
    } else {
      return this.http
        .get<CvUserProfile>(this.config.initialUserProfileUrl)
        .pipe(catchError(this.handleError<CvUserProfile>('getUserProfile')));
    }
  }

  getUserData(): Observable<CvUserData> {
    if (this.userData !== undefined) {
      return of(this.userData);
    } else if (this.hasStoredUserData()) {
      this.loadUserData();
      return of(this.userData);
    } else {
      return of(
        JSON.parse(JSON.stringify(this.config.defaultUserData)) as CvUserData
      );
    }
  }

  setUserData(userData: CvUserData): void {
    this.userData = userData;
    this.storeUserData();
  }

  storeUserData() {
    window.sessionStorage.setItem('data', JSON.stringify(this.userData));
  }

  loadUserData() {
    this.userData = JSON.parse(window.sessionStorage.getItem('data'));
  }

  hasStoredUserData() {
    return window.sessionStorage.getItem('data') !== null;
  }

  storeUserDonation(userDonation: CvUserDonation) {
    if (userDonation.amount === 50) {
      userDonation.choice = 'D50';
    } else if (userDonation.amount === 100) {
      userDonation.choice = 'D100';
    } else if (userDonation.amount > 0) {
      userDonation.choice = 'DC';
    } else {
      userDonation.choice = 'D0';
    }
    window.sessionStorage.setItem('donation', JSON.stringify(userDonation));
  }

  loadUserDonation() {
    if (window.sessionStorage.getItem('donation') !== null) {
      return JSON.parse(window.sessionStorage.getItem('donation'));
    } else {
      const d = new CvUserDonation();
      d.amount = 0;
      d.choice = 'D0';
      return d;
    }
  }

  storeUserItems() {
    window.sessionStorage.setItem('items', JSON.stringify(this.userItems));
  }

  loadUserItems() {
    if (window.sessionStorage.getItem('items') !== null) {
      this.userItems = JSON.parse(window.sessionStorage.getItem('items'));
      return this.userItems;
    } else {
      return [];
    }
  }

  getUserItemCount() {
    return this.userItems.length;
  }

  addUserItem(item: CvUserItems) {
    if (item.removable === undefined) {
      item.removable = true;
    }
    this.userItems.push(item);
    this.storeUserItems();
  }

  setUserProfile(userProfile: CvUserProfile): void {
    this.userProfile = userProfile;
  }

  getPricePerTon() {
    return this.config.chargePerTon;
  }

  calculatePriceForMetricton(mt: number) {
    return this.config.chargePerTon * mt;
  }

  calculateEmissionForGeneralLifeStyle(
    generalLifeStyleData: CvGeneralLifestyleData
  ) {
    const pet = generalLifeStyleData.pets;
    generalLifeStyleData.members[0].pets = pet;
    return this.http
      .post<CvGeneralLifestyleData>(
        this.config.generalLifeStyleCalculationUrl,
        generalLifeStyleData
      )
      .pipe(
        catchError(this.handleError<CvUserData>('generalLifeStyleCalculation'))
      );
  }

  getGeneralLifeStyleBenchmark(externalReferenceId: string): Observable<any> {
    return this.http
      .post<any>(this.config.generalLifeStyleBenchmarkUrl, externalReferenceId)
      .pipe(catchError(this.handleError<CvPromotioncode>('getBenchmark')));
  }

  calculateEmissionForGeneralLifeStyleCountryAverage() {
    const generalLifeStyleData = JSON.parse(
      JSON.stringify(this.config.defaultUserData.lifeStyle)
    );
    generalLifeStyleData.country = this.userData.lifeStyle.country;
    return this.http
      .post<CvGeneralLifestyleData>(
        this.config.generalLifeStyleCalculationUrl,
        generalLifeStyleData
      )
      .pipe(
        catchError(
          this.handleError<CvUserData>(
            'calculateEmissionForGeneralLifeStyleCountryAverage'
          )
        )
      );
  }

  calculateEmissionForGeneralLifeStyleWorldAverage() {
    const generalLifeStyleData = JSON.parse(
      JSON.stringify(this.config.defaultUserData.lifeStyle)
    );
    generalLifeStyleData.country = 'world';
    return this.http
      .post<CvGeneralLifestyleData>(
        this.config.generalLifeStyleCalculationUrl,
        generalLifeStyleData
      )
      .pipe(
        catchError(
          this.handleError<CvUserData>(
            'calculateEmissionForGeneralLifeStyleWorldAverage'
          )
        )
      );
  }

  calculateEmissionForGeneralLifeStyleOrgAverage() {
    const generalLifeStyleData = JSON.parse(
      JSON.stringify(this.config.defaultUserData.lifeStyle)
    );
    generalLifeStyleData.externalReferenceId = this.userData.clientCode;

    return this.http
      .post<CvGeneralLifestyleData>(
        this.config.generalLifeStyleCalculationUrl,
        generalLifeStyleData
      )
      .pipe(
        catchError(
          this.handleError<CvUserData>(
            'calculateEmissionForGeneralLifeStyleWorldAverage'
          )
        )
      );
  }

  addTransaction(data) {
    return this.http
      .post<CvTransaction>(this.config.addTransactionUrl, data)
      .pipe(catchError(this.handleError<CvTransaction>('addTransaction')));
  }

  initiateTransaction(data) {
    return this.http
      .post<CvCheckoutSession>(this.config.addTransactionUrl, data)
      .pipe(catchError(this.handleError<CvCheckoutSession>('addTransaction')));
  }

  prepareGivingUrl(transaction: CvTransaction) {
    const gUrl = new URL(this.config.givingUrl);

    const giftAmnt =
      transaction.totalAmount * 1 + transaction.donation.amount * 1;
    gUrl.searchParams.append('gift_amt_field', giftAmnt.toFixed(2) + '');
    gUrl.searchParams.append('notehidden', transaction.transactionUid);

    gUrl.searchParams.append(
      'donor_email_addressname',
      transaction.customer.email + ''
    );

    gUrl.searchParams.append(
      'donor_first_namename',
      transaction.customer.firstname + ''
    );
    gUrl.searchParams.append(
      'donor_last_namename',
      transaction.customer.lastname + ''
    );
    gUrl.searchParams.append(
      'donor_addr_street1name',
      transaction.customer.address.address1 + ''
    );
    gUrl.searchParams.append(
      'donor_addr_street2name',
      transaction.customer.address.address2 + ''
    );
    gUrl.searchParams.append(
      'donor_addr_cityname',
      transaction.customer.address.city + ''
    );
    gUrl.searchParams.append(
      'donor_addr_state',
      transaction.customer.address.state + ''
    );
    gUrl.searchParams.append(
      'donor_addr_zipname',
      transaction.customer.address.postcode + ''
    );
    gUrl.searchParams.append(
      'donor_addr_country',
      transaction.customer.address.country + ''
    );

    return gUrl.href;
  }

  loadCars() {
    if (this.cars !== undefined) {
      return of(this.cars);
    } else {
      return this.http
        .get<string[]>(this.config.carsListUrl)
        .pipe(catchError(this.handleError<CvUserData>('loadCars')));
    }
  }

  getUserCountry() {
    return this.http
      .get<any>(this.config.userCountryUrl)
      .pipe(tap((error) => catchError(this.handleError<CvUserData>(error))));
  }

  loadCountries() {
    if (this.countries !== undefined) {
      return of(this.countries);
    } else {
      return this.http.get<CvCountry[]>(this.config.countriesListUrl).pipe(
        tap(
          (data) => (this.countries = data),
          (error) => catchError(this.handleError<CvUserData>(error))
        )
      );
    }
  }

  loadUsStates() {
    if (this.usStates !== undefined) {
      return of(this.usStates);
    } else {
      return this.http.get<string[]>(this.config.usStatesListUrl).pipe(
        tap(
          (data) => (this.usStates = data),
          (error) => catchError(this.handleError<CvUserData>(error))
        )
      );
    }
  }

  calculateChargeByMetricton(data: CvMetricton): Observable<CvMetricton> {
    return this.http
      .post<CvMetricton>(this.config.metrictonUrl, data)
      .pipe(
        catchError(this.handleError<CvMetricton>('calculateChargeByMetricton'))
      );
  }

  getPromotioncode(data: string): Observable<CvPromotioncode> {
    return this.http
      .post<CvPromotioncode>(this.config.apeInvitationCodeUrl, data)
      .pipe(catchError(this.handleError<CvPromotioncode>('getPromotioncode')));
  }

  userPromotioncode(data: CvPromotioncode): Observable<CvPromotioncode> {
    return this.http
      .post<CvPromotioncode>(this.config.apeUseInvitationCodeUrl, data)
      .pipe(catchError(this.handleError<CvPromotioncode>('userPromotioncode')));
  }

  calculateApeChargeByMetricton(data: CvMetricton): Observable<CvMetricton> {
    return this.http
      .post<CvMetricton>(this.config.apeMetrictonUrl, data)
      .pipe(
        catchError(
          this.handleError<CvMetricton>('calculateApeChargeByMetricton')
        )
      );
  }

  private log(txt) {}

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
