<div class="container">
  <div class="row">
    <div class="col-md-12 col-xs-12">
     <div class="cv-page-info">
      <h1>Thank you for your contribution</h1>
      <p>
        A Carbon Warrior Badge certificate and receipt will be emailed to you
        <br>
        <br>
        Carbon Reduction tips
        <br>
        There are many ways to reduce enegry consumption, conserve and reduce<br>
        emission with no costs<br>
        <a href="">Learn more</a>
        <br>
        <br>
        <a routerLink="/home">Return to home page</a>
      </p>
     </div>
    </div>
  </div>
</div>