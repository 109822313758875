<div class="cv-container">
  <div class="row text-center">
    <div class="col-md-5 col-xs-12 cv-col-l bg-secondary">
      <div class="cv-pg-info">
        <h1>Mitigate Your Impact</h1>
        <p>
          Here's how your household’s annual carbon footprint compares to the
          average household of your size in your country and around the world.
          Donate now to offset your footprint and achieve net zero while
          supporting our efforts to foster the development of carbon removal
          technologies.
        </p>
      </div>

      <div class="row cv-leftFooter" style="display: none">
        <a routerLink="/home">Return home</a>
      </div>
    </div>
    <div class="col-md-7 col-xs-12 cv-col-r">
      <div style="padding: 50px">
        <h2>
          Your carbon footprint
          <a routerLink="/payment"
            ><i class="fa fa-shopping-cart"
              ><span
                class="badge badge-info"
                [hidden]="this.userItemCount < 1"
                >{{ this.userItemCount }}</span
              ></i
            ></a
          >
        </h2>

        <table border="0" cellpadding="0">
          <tr class="cv-chart">
            <td>
              <div
                class="bg-primary"
                [ngStyle]="{ height: this.resultPx + 'px' }"
              ></div>
            </td>
            <td>
              <div
                class="bg-colorB"
                [ngStyle]="{ height: this.coResultPx + 'px' }"
              ></div>
            </td>
            <td>
              <div
                class="bg-colorB"
                [ngStyle]="{ height: this.worldResultPx + 'px' }"
              ></div>
            </td>
            <td>
              <div
                class="bg-colorB"
                [ngStyle]="{ height: this.orgResultPx + 'px' }"
              ></div>
            </td>
          </tr>
          <tr class="cv-results">
            <td>
              <div>
                <img src="/assets/img/loading.gif" *ngIf="this.result == 0" />
                <i
                  class="fa fa-exclamation-circle"
                  *ngIf="this.result == null"
                  title="Error getting data from server"
                ></i>
                <h4>{{ this.result > 0 ? this.result : '' }}</h4>
              </div>
              <div></div>
              <em>tons of Co2</em>
              <p>Your annual carbon output</p>
            </td>
            <td>
              <div>
                <img src="/assets/img/loading.gif" *ngIf="this.coResult == 0" />
                <i
                  class="fa fa-exclamation-circle"
                  *ngIf="this.coResult == null"
                  title="Error getting data from server"
                ></i>
                <h4>{{ this.coResult > 0 ? this.coResult : '' }}</h4>
              </div>
              <em>tons of Co2</em>
              <p>
                Output of <br />{{
                  membersCount == 1 ? '1 person' : membersCount + ' people'
                }}
                <br />in <b>{{ this.userData.lifeStyle.countryName }}</b>
              </p>
            </td>
            <td>
              <div>
                <img
                  src="/assets/img/loading.gif"
                  *ngIf="this.worldResult == 0"
                />
                <i
                  class="fa fa-exclamation-circle"
                  *ngIf="this.worldResult == null"
                  title="Error getting data from server"
                ></i>
                <h4>{{ this.worldResult > 0 ? this.worldResult : '' }}</h4>
              </div>
              <em>tons of Co2</em>
              <p>
                Output of <br />{{
                  membersCount == 1 ? '1 person' : membersCount + ' people'
                }}
                <br />in the <b>world</b>
              </p>
            </td>
            <td *ngIf="orgResult !== -1">
              <div>
                <img src="/assets/img/loading.gif" *ngIf="orgResult == 0" />
                <i
                  class="fa fa-exclamation-circle"
                  *ngIf="this.orgResult == null"
                  title="Error getting data from server"
                ></i>
                <h4>{{ this.orgResult > 0 ? this.orgResult : '' }}</h4>
              </div>
              <em>tons of Co2</em>
              <p>
                Output of <br />{{
                  membersCount == 1 ? '1 person' : membersCount + ' people'
                }}
                <br />in the <b>organization</b>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <div class="cv-btns">
        <div class="row">
          <button
            class="button button-hollow"
            (click)="buyNow()"
            title="Donate Now"
          >
            Donate Now
          </button>
          <!-- button class="button" (click)="addToBasket()">ADD TO CART</button-->
        </div>
        <div class="row"></div>
      </div>

      <div class="row">
        <div class="col text-left"></div>
        <div class="col text-right">
          <a routerLink="/general-about-you" title="Recalculate">Recalculate</a>
        </div>
      </div>
    </div>
  </div>
</div>

