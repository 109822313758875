import { CvCountry } from './../../models/cv-country';
import { CvUserService } from './../../services/cv-user.service';
import { CvUserData } from './../../models/cv-user-data';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { CvGeneralLifestyleData } from 'src/app/models/cv-general-lifestyle-data';

@Component({
  selector: 'app-cv-offset-now-me',
  templateUrl: './cv-offset-now-me.component.html',
  styleUrls: ['./cv-offset-now-me.component.css']
})
export class CvOffsetNowMeComponent implements OnInit {

  userData: CvUserData;
  countryList: CvCountry[] = [];
  activeTab = 'tons'; // tons or location
  totalAmount = 0.00;
  pricePerTon = 0.0;

  constructor(private userService: CvUserService) {

  }

  ngOnInit() {

    this.pricePerTon = this.userService.getPricePerTon();

    this.userService.getUserData()
      .subscribe((data: CvUserData) => {
        this.userData = data;

        this.userService.loadCountries()
          .subscribe((countries: CvCountry[]) => {
            this.countryList = countries;
          });

        this.userService.getUserCountry()
          .subscribe((userCountry: any) => {
            this.userData.lifeStyle.country = userCountry.country_code;
          });

      });

  }

  addMetricTon(n) {
    this.userData.offsetNow.metricTons = Math.max(this.userData.offsetNow.metricTons + n, 1);

    this.totalAmount = this.userService.calculatePriceForMetricton(this.userData.offsetNow.metricTons);
  }

}
