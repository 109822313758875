import { CvPaymentResultComponent } from './components/cv-payment-result/cv-payment-result.component';
import { CvPackageComponent } from './components/cv-package/cv-package.component';
import { CvInvitationcodeComponent } from './components/cv-invitationcode/cv-invitationcode.component';
import { CvGeneralLifestyleComponent } from './components/cv-general-lifestyle/cv-general-lifestyle.component';
import { CvCarsComponent } from './components/cv-cars/cv-cars.component';
import { CvResultsComponent } from './components/cv-results/cv-results.component';
import { CvGeneralFlightComponent } from './components/cv-general-flight/cv-general-flight.component';
import { CvCommuteComponent } from './components/cv-commute/cv-commute.component';
import { CvDietComponent } from './components/cv-diet/cv-diet.component';
import { CvNavBarComponent } from './components/cv-nav-bar/cv-nav-bar.component';
import { CvConfirmationComponent } from './components/cv-confirmation/cv-confirmation.component';
import { CvSelectionComponent } from './components/cv-selection/cv-selection.component';
import { CvOffsetGiftComponent } from './components/cv-offset-gift/cv-offset-gift.component';

import { CvHomeComponent } from './components/cv-home/cv-home.component';
import { CvOffsetNowComponent } from './components/cv-offset-now/cv-offset-now.component';
import { CvOffsetNowMeComponent } from './components/cv-offset-now-me/cv-offset-now-me.component';

import { CvConfig } from './services/cv-config';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CvAboutYouComponent } from './components/cv-about-you/cv-about-you.component';
import { CvPaymentComponent } from './components/cv-payment/cv-payment.component';


@NgModule({
   declarations: [
      AppComponent,
      CvHomeComponent,
      CvNavBarComponent,
      CvOffsetNowComponent,
      CvOffsetNowMeComponent,
      CvOffsetGiftComponent,
      CvAboutYouComponent,
      CvDietComponent,
      CvSelectionComponent,
      CvResultsComponent,
      CvPaymentComponent,
      CvPaymentResultComponent,
      CvConfirmationComponent,
      CvCommuteComponent,
      CvCarsComponent,
      CvGeneralFlightComponent,
      CvGeneralLifestyleComponent,
      CvInvitationcodeComponent,
      CvPackageComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      MatCardModule,
      MatButtonModule,
      MatSelectModule,
      MatFormFieldModule,
      MatInputModule,
      MatDividerModule,
      MatDatepickerModule,
      MatStepperModule,
      MatRippleModule,
      MatCheckboxModule,
      MatRadioModule,
      HttpClientModule
   ],
   providers: [
      {
         provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
         useValue: { appearance: 'standard' }
      },
      {
         provide: MAT_RADIO_DEFAULT_OPTIONS,
         useValue: { color: 'accent' },
      }
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
