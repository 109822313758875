<div class="cv-container">
  <div class="row text-center">
    <div class="col-md-5 col-xs-12 cv-col-l">
      <div class="cv-pg-info">
        <h1>Gifts that<br>fight climate <br>change</h1>
        <p>
          Gift honest carbon offsets and help your<br> friend and family save the world
        </p>
      </div>

    </div>
    <div class="col-md-7 col-xs-12 cv-col-r">
      <div class="cv-col-pad">
        <div class="cv-offset-gift">
          <form>
            <div class="row">
              <div class="form-group col-md-6">
                <label class="control-label">Your name</label>
                <input type="text" class="form-control" placeholder="Your name">
              </div>
              <div class="form-group col-md-6">
                <label class="control-label">Your email address</label>
                <input type="text" class="form-control" placeholder="Email address">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label class="control-label">Recipient name</label>
                <input type="text" class="form-control" placeholder="Recipient name">
              </div>
              <div class="form-group col-md-6">
                <label class="control-label">Recipient email address</label>
                <input type="text" class="form-control" placeholder="Email address">
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">Gift is for a</label>
              <select formControlName="occasion" class="form-control" placeholder="select">
                <option selected disabled>Select occasion</option>
              </select>
            </div>
            <p class="control-label">Recipient's location</p>
            <div class="row">
              <div class="form-group col-md-6">
                <select formControlName="country" class="form-control" placeholder="select">
                  <option selected disabled>Country</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <select formControlName="state" class="form-control" placeholder="select">
                  <option selected disabled>State/Province</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">Message</label>
              <textarea class="form-control">Optional</textarea>
            </div>
            <label class="control-label">Select number of months or years to offset</label>
            <div class="form-group">
              <label class="radio-inline cv-btn cv-btn-default"
                >
                <input type="radio" formControlName="offsetPeriod" value="3"> 3 Months
              </label>
              <span class="cv-gap"></span>
              <label class="radio-inline cv-btn cv-btn-default"
                >
                <input type="radio" formControlName="offsetPeriod" value="6"> 6 Months
              </label>
              <span class="cv-gap hidden-xs"></span>
              <label class="radio-inline cv-btn cv-btn-default"
                >
                <input type="radio" formControlName="offsetPeriod" value="12"> 1 Year
              </label>
              <span class="cv-gap"></span>
              <label class="radio-inline cv-btn cv-btn-default"
                >
                <input type="radio" formControlName="offsetPeriod" value="-1"> Custom
              </label>
            </div>
          </form>
          <a class="cv-btn" href="/payment">PROCEED TO PAYMENT</a>
        </div>
      </div>
    </div>
  </div>
</div>