import { CvCountry } from './../../models/cv-country';
import { CvHouseHoldData } from './../../models/cv-general-lifestyle-data';
import { CvUserService } from './../../services/cv-user.service';
import { CvUserData } from './../../models/cv-user-data';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cv-about-you',
  templateUrl: './cv-about-you.component.html',
  styleUrls: ['./cv-about-you.component.css']
})
export class CvAboutYouComponent implements OnInit {

  household: number;
  userData: CvUserData;
  countryList: CvCountry[] = [];

  constructor(private userService: CvUserService, private router: Router) { }

  ngOnInit() {


    this.userService.getUserData()
      .subscribe((data: CvUserData) => {
        this.userData = data;
        this.household = this.userData.lifeStyle.members.length;
        this.userService.loadCountries()
          .subscribe((countries: CvCountry[]) => {
            this.countryList = countries;
          });

        if (this.userData.lifeStyle.country === 'US') {
          this.userService.getUserCountry()
            .subscribe((userCountry: any) => {
              this.userData.lifeStyle.country = userCountry.country_code;
            });
        }

      });
  }

  updateMembers() {
    const n = this.userData.lifeStyle.members.length;
    if (n < this.household) {
      for (let i = n; i < this.household; i++) {
        this.addMember();
      }
    } else {
      for (let i = this.household; i < n; i++) {
        this.removeMember();
      }
    }
  }

  addMember() {
    const mbId = this.userData.lifeStyle.members.length + 1;
    this.userData.lifeStyle.members.push({
      id: mbId,
      name: '',
      commute: 'AVERAGE',
      flight: 'AVERAGE',
      diet: 'AVERAGE'
    } as CvHouseHoldData);
  }

  removeMember() {
    this.userData.lifeStyle.members.pop();
  }

  moveNext() {
    const n = this.userData.lifeStyle.members.length;
    for (let i = 0; i < n; i++) {
      if (this.userData.lifeStyle.members[i].name === '') {
        this.userData.lifeStyle.members[i].name = 'Person ' + (i + 1);
      }
    }

    for (const c of this.countryList) {
      if (c.code === this.userData.lifeStyle.country) {
        this.userData.lifeStyle.countryName = c.name;
        break;
      }
    }
    this.userService.setUserData(this.userData);
    this.router.navigate(['/general-diet', {}]);
  }
}
