import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cv-offset-now',
  templateUrl: './cv-offset-now.component.html',
  styleUrls: ['./cv-offset-now.component.css']
})
export class CvOffsetNowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
