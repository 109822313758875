<div class="cv-container">
  <app-cv-nav-bar [pageTitle]="'General Lifestyle Calculator'"></app-cv-nav-bar>
  <div class="row text-center">
    <div class="col-12">
      <div class="cv-pg-info">
        <div class="cv-pg-title">
          <a
            routerLink="/general-diet"
            class="button button-hollow button-prev back-btn"
            title="Back"
            >Back</a
          >
          <h2><span style="color: #ccc">3/4</span> Commute</h2>
          <p>What is your primary mode of transportation?</p>
        </div>
        <div class="cv-pg-content cv-mb-choice">
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            *ngIf="
              this.userData.lifeStyle.members.length == 1 || !this.isMobile
            "
          >
            <tr class="cv-mb-choice-header">
              <td class="col-4">
                <div
                  class="form-check"
                  [hidden]="this.userData.lifeStyle.members.length < 2"
                >
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      [(ngModel)]="applyAll"
                      (change)="applyToAll()"
                      type="checkbox"
                    />
                    Apply same answer <br />
                    to all members
                  </label>
                </div>
              </td>
              <td
                class="col-2"
                *ngFor="let m of this.userData.lifeStyle.members"
              >
                <div class="cv-member-lbl">{{ m.name }}</div>
              </td>
            </tr>
            <tr class="cv-mb-choices">
              <td class="col-4">
                <span class="cv-option-lbl" *ngIf="isCountryWithDetails()">
                  Average commuter<br />
                  <em>Small and Medium Cars</em>
                </span>
                <span class="cv-option-lbl" *ngIf="!isCountryWithDetails()">
                  Average commuter<br />
                  <em
                    >Small and medium-sized cars or Other modes of transport
                    (such as bus, train, walking etc.)</em
                  >
                </span>
              </td>
              <td *ngFor="let m of this.userData.lifeStyle.members">
                <input
                  class="form-check-input"
                  type="radio"
                  name="{{ 'r' + m.id }}"
                  (change)="applyToAll()"
                  disabled="{{ m.id > 1 && this.applyAll }}"
                  [(ngModel)]="m.commute"
                  value="AVERAGE"
                />
              </td>
            </tr>
            <tr class="cv-mb-choices" *ngIf="isCountryWithDetails()">
              <td class="col-4">
                <span class="cv-option-lbl">
                  Do not drive<br />
                  <em>Walk, Bike, Rollerblades, etc</em>
                </span>
              </td>
              <td *ngFor="let m of this.userData.lifeStyle.members">
                <input
                  class="form-check-input"
                  type="radio"
                  name="{{ 'r' + m.id }}"
                  (change)="applyToAll()"
                  disabled="{{ m.id > 1 && this.applyAll }}"
                  [(ngModel)]="m.commute"
                  value="DO_NOT_DRIVE"
                />
              </td>
            </tr>
            <tr class="cv-mb-choices" *ngIf="isCountryWithDetails()">
              <td class="col-4">
                <span class="cv-option-lbl">
                  Only Public Transit<br />
                  <em>Bus, Light Rail, Subway, etc</em>
                </span>
              </td>
              <td *ngFor="let m of this.userData.lifeStyle.members">
                <input
                  class="form-check-input"
                  type="radio"
                  name="{{ 'r' + m.id }}"
                  (change)="applyToAll()"
                  disabled="{{ m.id > 1 && this.applyAll }}"
                  [(ngModel)]="m.commute"
                  value="ONLY_PUBLIC"
                />
              </td>
            </tr>
            <tr class="cv-mb-choices" *ngIf="isCountryWithDetails()">
              <td class="col-4">
                <span class="cv-option-lbl">
                  Electric Vehicles<br />
                  <em>Hybrid and Full Electric</em>
                </span>
              </td>
              <td *ngFor="let m of this.userData.lifeStyle.members">
                <input
                  class="form-check-input"
                  type="radio"
                  name="{{ 'r' + m.id }}"
                  (change)="applyToAll()"
                  disabled="{{ m.id > 1 && this.applyAll }}"
                  [(ngModel)]="m.commute"
                  value="ELECTRIC_VEHICLE"
                />
              </td>
            </tr>
            <tr class="cv-mb-choices" *ngIf="isCountryWithDetails()">
              <td class="col-4">
                <span class="cv-option-lbl">
                  Only Carpool<br />
                  <em>Car-sharing or Ride-sharing</em>
                </span>
              </td>
              <td *ngFor="let m of this.userData.lifeStyle.members">
                <input
                  class="form-check-input"
                  type="radio"
                  name="{{ 'r' + m.id }}"
                  (change)="applyToAll()"
                  disabled="{{ m.id > 1 && this.applyAll }}"
                  [(ngModel)]="m.commute"
                  value="ONLY_CARPOOL"
                />
              </td>
            </tr>
            <tr class="cv-mb-choices">
              <td class="col-4">
                <span class="cv-option-lbl">
                  Large Vehicles<br />
                  <em>SUV, Pickup or Van</em>
                </span>
              </td>
              <td *ngFor="let m of this.userData.lifeStyle.members">
                <input
                  class="form-check-input"
                  type="radio"
                  name="{{ 'r' + m.id }}"
                  (change)="applyToAll()"
                  disabled="{{ m.id > 1 && this.applyAll }}"
                  [(ngModel)]="m.commute"
                  value="LARGE_VEHICLE"
                />
              </td>
            </tr>
          </table>
          <div
            class="col-12 mobile"
            *ngIf="this.userData.lifeStyle.members.length > 1 && this.isMobile"
          >
            <label class="form-check-label">
              <input
                class="form-check-input"
                [(ngModel)]="applyAll"
                (change)="applyToAll()"
                type="checkbox"
              />
              Apply same answer to all members
            </label>
            <div class="row" *ngFor="let m of this.userData.lifeStyle.members">
              <label class="form-check-label">
                {{ m.name }}
              </label>
              <select
                [(ngModel)]="m.commute"
                (change)="applyToAll()"
                disabled="{{ m.id > 1 && this.applyAll }}"
              >
                <option value="AVERAGE" *ngIf="isCountryWithDetails()">
                  <span class="cv-option-lbl">Average commuter</span>
                  <em>Small and Medium Cars</em>
                </option>
                <option value="AVERAGE" *ngIf="!isCountryWithDetails()">
                  <span class="cv-option-lbl">Average commuter</span>
                  <em
                    >Small and medium-sized cars or Other modes of transport
                    (such as bus, train, walking etc.)</em
                  >
                </option>
                <option value="DO_NOT_DRIVE" *ngIf="isCountryWithDetails()">
                  <span class="cv-option-lbl">Do not drive</span>
                  <em>Walk, Bike, Rollerblades, etc</em>
                </option>
                <option value="ONLY_PUBLIC" *ngIf="isCountryWithDetails()">
                  <span class="cv-option-lbl">Only Public Transit</span>
                  <em>Bus, Light Rail, Subway, etc</em>
                </option>
                <option value="ELECTRIC_VEHICLE" *ngIf="isCountryWithDetails()">
                  <span class="cv-option-lbl">Electric Vehicles</span>
                  <em>Hybrid and Full Electric</em>
                </option>
                <option value="ONLY_CARPOOL" *ngIf="isCountryWithDetails()">
                  <span class="cv-option-lbl">Only Carpool</span>
                  <em>Car-sharing or Ride-sharing</em>
                </option>
                <option value="LARGE_VEHICLE">
                  <span class="cv-option-lbl">Large Vehicles</span>
                  <em>SUV, Pickup or Van</em>
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <a
      routerLink="/general-diet"
      class="button button-hollow button-prev back-btn"
      title="Back"
      >Back</a
    >
    <button (click)="moveNext()" class="button button-next next-btn">
      Next
    </button>
  </div>
</div>
