<div class="cv-container">
  <app-cv-nav-bar [pageTitle]="'Car Calculator'"></app-cv-nav-bar>
  <div class="row text-center">
    <div class="col-12">
      <div class="cv-pg-info">

        <ul class="cv-tabs">
          <li [ngClass]="{'cv-tab-selected': (activeTab == 'model')}">
            <a (click)="activeTab='model'">Calculate by Car Make and Model</a>
          </li>
          <li [ngClass]="{'cv-tab-selected': (activeTab == 'country')}">
            <a (click)="activeTab='country'">Calculate by Country</a>
          </li>
        </ul>

        <div [hidden]="(activeTab == 'country')" class="row">
          <div class="col-md-5 col-xs-12 cv-col-l">
            <div class="cv-pg-info">
              <div class="form-group">
                <label class="control-label">Car manufacturer</label>
                <select [(ngModel)]="car.make" class="form-control" placeholder="Car Manufacturer">
                  <option *ngFor="let make of carMakes" value="{{make}}" [selected]="car.make==make">
                    {{make}}</option>
                </select>
              </div>

              <label class="control-label">Model</label>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <select [(ngModel)]="car.model" class="form-control" placeholder="Car Manufacturer">
                      <option *ngFor="let model of carModels" value="{{model}}" [selected]="car.model==model">
                        {{model}}</option>
                    </select>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <select [(ngModel)]="car.model" class="form-control" placeholder="Car Manufacturer">
                      <option *ngFor="let model of carModels" value="{{model}}" [selected]="car.model==model">
                        {{model}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label class="control-label">Transmission type</label>
                    <select [(ngModel)]="car.transmission" class="form-control" placeholder="Car Transmission">
                      <option *ngFor="let transmission of carTransmissions" value="{{transmission}}"
                        [selected]="car.transmission==transmission">
                        {{transmission}}</option>
                    </select>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="control-label">Engine type</label>
                    <select [(ngModel)]="car.engine" class="form-control" placeholder="Car Engine">
                      <option *ngFor="let engine of carEngines" value="{{engine}}" [selected]="car.engine==engine">
                        {{engine}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label class="control-label">Distance driven</label>
                    <input type="number" [(ngModel)]="car.distance" class="form-control" placeholder="Distance" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="control-label">Number years to offset</label>
                    <input type="number" [(ngModel)]="car.years" class="form-control" placeholder="Offset" />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col">
                  <div class="form-group radioFields">
                    <label class="control-label">
                      <input type="radio" [(ngModel)]="car.distanceUnit" value="ml" />Miles
                    </label>
                    <label class="control-label">
                      <input type="radio" [(ngModel)]="car.distanceUnit" value="km" />Kilometers
                    </label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="control-label">&nbsp;</label>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col">
                  <button (click)="moveNext()" style="width:60%" class="btn btn-outline-success">Add Car</button>
                </div>
              </div>

            </div>
          </div>
          <div class="col-md-7 col-xs-12 cv-col-r">
            <div class="cv-col-pad">
              <div class="myCars">
                <h5 class="title">My Cars</h5>
                <ul>
                  <li>
                    <a hrer>x</a>
                    <p>
                      <span>Toyota Yaris 2012</span>
                      Automatic Transmission, Inline Engine, 99,000 miles Distance Driven
                    </p>
                  </li>
                  <li>
                    <a hrer>x</a>
                    <p>
                      <span>Toyota Yaris 2012</span>
                      Automatic Transmission, Inline Engine, 99,000 miles Distance Driven
                    </p>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
        <div class="footer">
          <a routerLink="/selection" class="back-btn">&#60; Back</a>

        </div>
      </div>