import { CvPromotioncode, CvMetricton } from './../../models/cv-invitationcode';
import { CvUserItems } from './../../models/cv-user-items';
import { Router } from '@angular/router';
import { CvConfig } from './../../services/cv-config';
import { CvUserService } from './../../services/cv-user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cv-invitationcode',
  templateUrl: './cv-invitationcode.component.html',
  styleUrls: ['./cv-invitationcode.component.scss']
})
export class CvInvitationcodeComponent implements OnInit {

  invitationcode = '';
  metrictons = 10;
  errorMessage = '';

  constructor(private userService: CvUserService, private config: CvConfig, private router: Router) { }

  ngOnInit() {
  }

  buyNow1() {

    if (this.metrictons !== null) {
      const item = new CvUserItems();
      item.category = 'METRICTON';
      item.amount = this.metrictons * 10;
      item.emission = this.metrictons;
      item.details = 'Metric tons';
      item.removable = false;
      this.userService.addUserItem(item);
      this.userService.storeUserItems();
      this.router.navigate(['/payment', {}]);
    }
  }

  processCharges(totalCharge: number) {
    const item = new CvUserItems();
    item.promocode = this.invitationcode;
    item.category = 'METRICTON';
    item.amount = totalCharge;
    item.emission = this.metrictons;
    item.details = 'Metric tons';
    item.removable = false;
    this.userService.addUserItem(item);
    this.userService.storeUserItems();
    this.router.navigate(['/payment', {}]);
  }

  buyNow() {
    this.userService.getPromotioncode(this.invitationcode)
      .subscribe((resultData: CvPromotioncode) => {

        if (resultData.isValid) {

          if (resultData.isUsed) {
            this.errorMessage = 'This invitation code is already used';
          } else {
            const mtData = new CvMetricton();
            mtData.metrictons = this.metrictons;

            this.userService.calculateChargeByMetricton(mtData)
              .subscribe((mtResult: CvMetricton) => {
                if (mtResult.charge * 1 > 0) {
                  this.processCharges(mtResult.charge);
                }
              });
          }
        } else {
          this.errorMessage = 'Invalid invitation code';
        }
      });


  }

}
